import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Dropdown from "../components/dropdown"
import titreRubrique from "../assets/images/accueil/puce_titre_rubrique.gif"
import mainTitle from "../assets/images/titres/notre_savoir-faire.gif"
import certifications from "../assets/images/titres/certifications_EC3D.gif"
import RichTextRenderer from "../components/RichTextRenderer";
import "./savoirFaire.css"
import 'react-slidedown/lib/slidedown.css'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const savoirFairePage = ({data}) => (
  <Layout>
    <SEO title="Notre Savoir-Faire" />
    <div id="savoirfaire" class="rubrique">
      <div id="titre">
          <img src={titreRubrique} width="25" height="32" alt="puce titre rubrique"/>
          <span class="h1"><img src={mainTitle} width="518" height="32" alt="Rubrique Notre Savoir-Faire - EC3D" /></span>
          <p class="titre-texte h2">
          </p>
      </div>
              
      <div id="contenu">

        <div class="block main-block">
          <div class="inner-block">
            <span class="h1">{ data.allContentfulNotreSavoirFaire.edges[0].node.subtitle }</span>
            <div class="h3" dangerouslySetInnerHTML={{ __html: documentToHtmlString(data.allContentfulNotreSavoirFaire.edges[0].node.introduction.json) }}>
            </div>
            <div class="dropdown-container">
              {data.allContentfulNotreSavoirFaire.edges[0].node.events.map(event => (
                <Dropdown title={event.title} images={event.images} content={event.texte ? event.texte.json : ""}/>
              ))}
            </div>
            <div class="h3 margin-top">{data.allContentfulNotreSavoirFaire.edges[0].node.middleText.middleText}</div>
            <div class="dropdown-container">
              {data.allContentfulNotreSavoirFaire.edges[0].node.measures.map(measure => (
                <Dropdown title={measure.title} images={measure.images} content={measure.texte ? measure.texte.json : ""}/>
              ))}
            </div>
          </div>
        </div>

        <div class="block side-block">
          <div class="inner-block">
            <div class="block-title h1">
              <img src={certifications} width="161" height="22" alt="Innovation EC3D" />
            </div>
            <div class="h3">
              <RichTextRenderer richTextJson={data.allContentfulNotreSavoirFaire.edges[0].node.certifications.texte.json} />
              <a href={data.allContentfulNotreSavoirFaire.edges[0].node.certifications.certifications.file.url}>
                Téléchargez nos certifications EN 9100 et ISO 9001 en cliquant ici.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default savoirFairePage

export const query = graphql`
    query {
      allContentfulNotreSavoirFaire {
        edges {
          node {
            id,
            subtitle,
            introduction {
              json
            },
            events{
              title,
              images{
                file{
                  url
                }
              },
              texte{
                json
              }
            },
            middleText{
              middleText
            },
            measures{
              title,
              images{
                file{
                  url
                }
              },
              texte{
                json
              }
            },
            certifications{
              texte{
                json
              },
              certifications{
                file{
                  url
                }
              }
            }
          }
        }
      }
    }
  `